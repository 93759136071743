import React from "react";
import { DialogActions, Button, DialogContent, DialogContentText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LinkStyle } from "app/utils/global_constants";
import { Link } from "react-router-dom";
import useAuth from "app/hooks/useAuth";
function ApplyDialogContent({ handleCloseDialog, isApplying, application, apply }) {
  const { user } = useAuth();
  return (
    <>
      <DialogContent>
        <DialogContentText sx={{ color: "black" }}>
          Review your profile before submitting:{" "}
          <Link to={`/candidate/detail/${user.id}`} target={"_blank"} style={LinkStyle}>
            View Profile
          </Link>
        </DialogContentText>
        <DialogContentText sx={{ color: "black" }}>
          Edit your profile if you need:{" "}
          <Link to={"/profile/2"} target="_blank" state={{ tab: "2" }} style={LinkStyle}>
            Edit Profile
          </Link>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isApplying}
          onClick={() => {
            if (application) {
              apply({ data: application });
            }
          }}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </>
  );
}

export default ApplyDialogContent;
