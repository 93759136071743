import { Container, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import AddAddressStep from "./shared/Apply Job/AddAddressStep";
import BasicDetailStep from "./shared/Apply Job/BasicDetailStep";
import CurrentEmploymentStep from "./shared/Apply Job/CurrentEmploymentStep";
import EducationDetailStep from "./shared/Apply Job/EducationDetailStep";
import FinalStep from "./shared/Apply Job/FinalStep";
import StepWrapper from "./shared/Apply Job/StepWrapper";
import UploadResumeStep from "./shared/Apply Job/UploadResumeStep";
import { FormProvider } from "./shared/Context/FormContext";

const steps = [
  "Add Basic Details",
  "Upload Resume and Profile Photo",
  "Add Current Employment Details",
  "Add Education Details",
  "Add Address",
  "Finish and Apply"
];

const StepContent = {
  0: <BasicDetailStep />,
  1: <UploadResumeStep />,
  2: <CurrentEmploymentStep />,
  3: <EducationDetailStep />,
  4: <AddAddressStep />,
  5: <FinalStep />
};

function ApplyJob(props) {
  const location = useLocation();
  const { selectedJob } = location.state;

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container sx={{ backgroundColor: "#eee", height: "100%", p: 3 }}>
      <FormProvider>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length - 1 ? (
          <FinalStep handleReset={handleReset} job={selectedJob} />
        ) : (
          <StepWrapper
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            handleSkip={handleSkip}
            steps={steps}
            isStepOptional={isStepOptional}
          >
            {StepContent[activeStep]}
          </StepWrapper>
        )}
      </FormProvider>
    </Container>
  );
}

export default ApplyJob;
