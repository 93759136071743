import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import FormUploadFile from "app/components/form-components/FormUploadFile";
import { isFileLimitExceeded } from "app/utils/utils";
import { useForm } from "react-hook-form";
import { useMultiStepForm } from "../Context/FormContext";
function UploadResumeStep(props) {
  const { FormState, updateField } = useMultiStepForm();
  const {
    register,
    formState: { errors },
    clearErrors,
    setError
  } = useForm();

  const onResumeUpload = (file) => {
    if (isFileLimitExceeded({ fileSize: file.size, limit: 1024 })) {
      alert("File size limit of 1MB exceeded");
      setError(
        "jp_resume",
        { type: "custom", message: "File size limit of 1MB exceeded" },
        { shouldFocus: true }
      );
      updateField(null);
    } else {
      clearErrors("jp_resume");
      updateField("jp_resume", file);
    }
  };

  const onPhotoUpload = (file) => {
    if (isFileLimitExceeded({ fileSize: file.size, limit: 1024 })) {
      alert("File size limit of 1 MB exceeded");
      setError(
        "jp_photo",
        { type: "custom", message: "File size limit of 1 MB exceeded" },
        { shouldFocus: true }
      );
    } else {
      clearErrors("jp_photo");
      updateField("jp_photo", file);
    }
  };
  return (
    <Box>
      <Typography variant="h5" fontWeight={"bold"}>
        Upload Resume and Profile Photo
      </Typography>
      <Typography variant="subtitle2" color={"gray"} mb={2}>
        *All fields are mandatory
      </Typography>
      <Box display={"flex"} gap={2}>
        <Box flex={1}>
          <Typography variant="subtitle2">Upload Resume</Typography>
          <FormUploadFile
            file={FormState.jp_resume}
            name={"jp_resume"}
            register={register}
            preloadedData={FormState.jp_resume}
            onUploadFile={onResumeUpload}
            fileType={"application/pdf"}
            errors={errors.jp_resume}
          />
        </Box>
        <Box flex={1}>
          <Typography variant="subtitle2">Upload Photo</Typography>
          <FormUploadFile
            file={FormState.jp_photo}
            name={"jp_photo"}
            errors={errors.jp_photo}
            preloadedData={FormState.jp_photo}
            register={register}
            onUploadFile={onPhotoUpload}
            fileType={"image/*"}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default UploadResumeStep;
