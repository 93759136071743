export const ACTIONS = {
  UPDATE_FIELD: "UPDATE_FIELD",
  RESET_FORM: "RESET_FORM"
};

export const FormInitialState = {
  jp_dob: new Date(),
  jp_age: "",
  jp_designation: "",
  jp_resume: null,
  jp_photo: null,
  jp_address: "",
  jp_country: "",
  jp_state: "",
  jp_city: "",
  jp_qualification: "",
  jp_degree_name: "",
  jp_passingyear: "",
  jp_current_company: "",
  jp_current_ctc: "",
  jp_notice_period: "",
  jp_yoe: 0,
  jp_skills: "",
  jp_about_me: ""
};

export const FormReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_FIELD:
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value
      };
    case ACTIONS.RESET_FORM:
      return FormInitialState;
    default:
      return state;
  }
};
