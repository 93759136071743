import React, { useReducer, useState } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useMultiStepForm } from "../Context/FormContext";
import useGetCountries from "../../hooks/useGetCountries";
import { CountryStateCityReducer } from "app/views/candidate/shared/reducer/CandidateAddressReducer";
import { CountryCodeMap } from "app/views/candidate/shared/Constants";
import { State, City } from "country-state-city";
import { uniqueId } from "lodash";
function AddAddressStep(props) {
  const { FormState, updateField } = useMultiStepForm();

  const [countryStateCityKey, setKey] = useState({
    country: 0,
    state: 1,
    city: 2
  });

  const [CountryStateCity, dispatch] = useReducer(CountryStateCityReducer, {
    country: FormState.jp_country || null,
    state: FormState.jp_state || null,
    city: FormState.jp_city || null
  });

  function getStateCode(currentState, states) {
    const code = states.find((state) => state.label === currentState).id;
    return code;
  }

  const countries = useGetCountries();

  const states = CountryStateCity.country
    ? State.getStatesOfCountry(CountryCodeMap[CountryStateCity.country]).map((state) => ({
        id: state.isoCode,
        label: state.name
      }))
    : [];

  const cities = CountryStateCity.state
    ? City.getCitiesOfState(
        CountryCodeMap[CountryStateCity.country],
        getStateCode(CountryStateCity.state, states)
      ).map((city) => ({ id: city.name, label: city.name }))
    : [];

  const isOptionEqualToValue = (option, value) => {
    if (value && typeof value === "object") {
      return option.label === value.label;
    }
    if (value && typeof value === "string") {
      return option.label === value;
    }
    return false;
  };

  return (
    <Box>
      <Typography variant="h5" fontWeight={"bold"}>
        Add Basic Detail
      </Typography>
      <Typography variant="subtitle2" color={"gray"} mb={2}>
        *All fields are mandatory
      </Typography>
      <TextField
        name={"jp_address"}
        label="Address *"
        defaultValue={FormState.jp_address}
        fullWidth
        onChange={(e) => updateField("jp_address", e.target.value)}
      />
      <Box display={"flex"} gap={2} mt={2}>
        <Autocomplete
          key={countryStateCityKey.country}
          fullWidth
          name={"jp_country"}
          options={countries}
          value={CountryStateCity.country}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(e, value) => {
            console.log("🚀 ~ AddAddressStep ~ value:", value);
            dispatch({ type: "SET_COUNTRY", payload: { country: value } });
            dispatch({ type: "SET_STATE", payload: { state: null } });
            dispatch({ type: "SET_CITY", payload: { city: null } });
            setKey((prev) => ({ ...prev, state: uniqueId(), city: uniqueId() }));
            updateField("jp_country", value.label);
            // setValue("jp_state", null);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Country *" placeholder="Country" />
          )}
        />
        <Autocomplete
          key={countryStateCityKey.state}
          name={"jp_state"}
          fullWidth
          value={CountryStateCity.state}
          options={states}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(e, value) => {
            dispatch({ type: "SET_STATE", payload: { state: value } });
            dispatch({ type: "SET_CITY", payload: { city: null } });
            setKey((prev) => ({ ...prev, city: uniqueId() }));
            updateField("jp_state", value.label);
            // setValue("jp_city", null);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select State *" placeholder="State" />
          )}
        />
        <Autocomplete
          key={countryStateCityKey.city}
          name={"jp_city"}
          options={cities}
          value={CountryStateCity.city}
          fullWidth
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(e, value) => {
            dispatch({ type: "SET_CITY", payload: { city: value } });
            updateField("jp_city", value.label);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select City *" placeholder="City" />
          )}
        />
      </Box>
    </Box>
  );
}

export default AddAddressStep;
