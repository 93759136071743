import React from "react";
import { Typography, Box, TextField } from "@mui/material";
import { useMultiStepForm } from "../Context/FormContext";
import { extractNumbers } from "app/utils/utils";
function CurrentEmploymentStep(props) {
  const { FormState, updateField } = useMultiStepForm();

  return (
    <Box>
      <Typography variant="h5" fontWeight={"bold"} mb={4}>
        Add Employment Details
      </Typography>
      <Box display={"flex"} gap={2} mb={2}>
        <TextField
          fullWidth
          defaultValue={FormState.jp_current_company}
          name={"jp_current_company"}
          label={"Current Company *"}
          onChange={(e) => updateField("jp_current_company", e.target.value)}
        />
        <TextField
          fullWidth
          value={FormState.jp_current_ctc}
          name={"jp_current_ctc"}
          label={"Current CTC *"}
          onChange={(e) => updateField("jp_current_ctc", extractNumbers(e.target.value))}
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <TextField
          fullWidth
          value={FormState.jp_yoe}
          name={"jp_yoe"}
          label={"Current YOE *"}
          onChange={(e) => {
            const value = e.target.value;
            updateField("jp_yoe", value.replace(/[^\d.]/g, ""));
          }}
        />
        <TextField
          fullWidth
          value={FormState.jp_notice_period}
          name={"jp_notice_period"}
          label={"Notice Period *"}
          onChange={(e) => updateField("jp_notice_period", extractNumbers(e.target.value))}
        />
      </Box>
    </Box>
  );
}

export default CurrentEmploymentStep;
