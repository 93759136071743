import { createContext, useContext, useReducer } from "react";
import { FormReducer, FormInitialState, ACTIONS } from "../Reducer/FormStateReducer";

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [FormState, dispatch] = useReducer(FormReducer, FormInitialState);

  const updateField = (field, value) => {
    dispatch({
      type: ACTIONS.UPDATE_FIELD,
      payload: { field, value }
    });
  };

  const resetForm = () => {
    dispatch({ type: ACTIONS.RESET_FORM });
  };

  const value = {
    FormState,
    updateField,
    resetForm
  };

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

// Custom hook to use the form context
export const useMultiStepForm = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useForm must be used within a FormProvider");
  }
  return context;
};
