import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { useMultiStepForm } from "../Context/FormContext";

const isEmpty = (value) => {
  if (value instanceof Date || value instanceof File) {
    return false; // A Date object should never be considered empty
  }
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" && Object.keys(value).length === 0)
  );
};

const STEP_VALIDATION_SCHEMA = {
  0: ["jp_dob", "jp_age", "jp_designation", "jp_about_me", "jp_skills"],
  1: ["jp_resume", "jp_photo"],
  2: ["jp_current_company", "jp_current_ctc", "jp_yoe", "jp_notice_period"],
  3: ["jp_qualification", "jp_degree_name", "jp_passingyear"],
  4: ["jp_address", "jp_country", "jp_state", "jp_city"]
};

const checkStepValidation = (activeStep, formState) => {
  // Get required fields for current step
  const requiredFields = STEP_VALIDATION_SCHEMA[activeStep];

  // If step doesn't exist in schema, return false
  if (!requiredFields) {
    // console.warn(`No validation schema found for step ${activeStep}`);
    return false;
  }

  // Check if any required field is empty
  return requiredFields.some((field) => isEmpty(formState[field]));
};

function StepWrapper({
  activeStep,
  handleBack,
  handleNext,
  handleSkip,
  steps,
  isStepOptional,
  children
}) {
  const { FormState } = useMultiStepForm();
  const isLastStep = activeStep === steps.length - 1;

  let isInputEmpty = checkStepValidation(activeStep, FormState);

  return (
    <Box>
      <Box sx={{ mt: 2, mb: 1, height: "100%" }}>{children}</Box>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        {isStepOptional(activeStep) && (
          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
            Skip
          </Button>
        )}
        <Button disabled={isInputEmpty} onClick={handleNext}>
          {isLastStep ? "Finish and Apply" : "Next"}
        </Button>
      </Box>
    </Box>
  );
}

export default StepWrapper;
