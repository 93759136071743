import { Box, Tooltip } from "@mui/material";
import GrayBGText from "./GrayBGText";
function JobCities({ cities }) {
  const remainingCities = cities.slice(1).join(" | ");
  return (
    <>
      {cities && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <GrayBGText loc={`${cities[0]} `} />
          {cities.length > 1 && (
            <Tooltip title={remainingCities}>
              <span style={{ cursor: "pointer" }}>
                <GrayBGText loc={`+${cities.length - 1}`} />
              </span>
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
}

export default JobCities;
