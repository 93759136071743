import { Box, Container, Stack, Typography } from "@mui/material";
import Loading from "app/components/MatxLoading";
import SquareProfile from "app/components/SquareProfile";
import StatusText from "app/components/StatusText";
import useGetApplicationDetail from "app/hooks/application/useGetApplicationDetail";
import useAuth from "app/hooks/useAuth";
import { convertISODateTimeToDate } from "app/utils/utils";
import { useParams } from "react-router-dom";
import CandidateInfoGrid from "./shared/application_detail_components/CandidateInfoGrid";
import DownloadResume from "./shared/application_detail_components/DownloadResume";
import LinkToJob from "./shared/application_detail_components/LinkToJob";
import CandidateFit from "./shared/CandidateFit";
import StatusDropdown from "./shared/StatusDropdown";
export const Subtitle = ({ label }) => {
  return (
    <Typography variant="subtitle2" color={"gray"} fontWeight={400}>
      {label}
    </Typography>
  );
};

const SectionStyles = {
  marginTop: "20px",
  marginBottom: "20px"
};

function ApplicationDetail(props) {
  const { id } = useParams();
  const { user } = useAuth();

  // Get application detail
  const { data: application, isFetching: isLoading } = useGetApplicationDetail({ id });

  let candidate = {};
  let candidateName = "";
  let companyName = "";
  let jobTitle = "";
  let candidateSkills = [];
  let requiredSkills = [];
  let isJobDeleted = 0;
  let isCandidateDeleted = false;
  let isUserCandidate = user.role === "candidate";

  if (application) {
    candidate = application.Candidate;
    candidateName = candidate.jp_candidate_name;
    jobTitle = application.jobposting.jp_title;
    candidateSkills = candidate.Skills.map((skill) => skill.jp_skill);
    requiredSkills = application.jobposting.jp_required_skills.split("| ");
    companyName = application.company.jp_company_name;
    isJobDeleted = application.jobposting.jp_is_deleted;
    isCandidateDeleted = application.Candidate.jp_is_deleted;
  }

  return (
    <Container sx={{ mt: 4 }}>
      {!application && isLoading && <Loading />}
      {application && (
        <>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ borderBottom: "1px solid lightgray", pb: 2 }}
          >
            <Box>
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <Typography variant="h5">Applied For: {application.jobposting.jp_title}</Typography>
                <LinkToJob
                  jobTitle={application.jobposting.jp_title}
                  jobPostId={application.jobposting.jp_id}
                />
              </Box>
              {isJobDeleted === 1 && (
                <Typography sx={{ color: "#ff9800" }} fontWeight={"bold"}>
                  {"(Application Closed)"}
                </Typography>
              )}
              <Typography variant="subtitle2" color={"gray"}>
                <strong style={{ color: "black" }}>Applied On: </strong>{" "}
                {convertISODateTimeToDate(application.jp_created_at)}
              </Typography>
            </Box>
            <Stack gap={1}>
              {!isCandidateDeleted && !isUserCandidate && (
                <StatusDropdown
                  status={application.jp_status}
                  shareStatus={application.jp_is_share_to_company}
                  jobTitle={jobTitle}
                  applicationId={application.jp_id}
                  candidateId={application.jp_candidate_id}
                  candidateName={candidateName}
                  companyId={application.jp_company_id}
                  companyName={companyName}
                  companyMail={application.company.jp_email}
                />
              )}
              {isUserCandidate && <StatusText status={application.jp_status} />}
              <DownloadResume candidateId={candidate.jp_id} candidateName={candidateName} />
            </Stack>
          </Box>
          <Typography variant="h6" mb={"-6px"}>
            Candidate Detail
          </Typography>
          {isCandidateDeleted && (
            <Typography color={"#ff9800"} mt={1}>
              (Candidate is no longer accepting offers)
            </Typography>
          )}
          <SquareProfile
            profile={candidate.jp_photo}
            name={candidateName}
            containerStyles={{ mt: 1 }}
          />
          {/* Basic Info */}
          <section style={{ marginTop: "10px" }}>
            <CandidateInfoGrid candidate={candidate} />
          </section>
          {/* About Candidate */}
          <section style={SectionStyles}>
            <Subtitle label={"About Candidate"} />
            <Typography variant="body1" fontWeight={500}>
              {application.Candidate.jp_about_me}
            </Typography>
          </section>
          {/* Skills */}
          <section style={SectionStyles}>
            <Box display={"flex"} gap={1}>
              <Subtitle label={"Skills"} />
              <CandidateFit candidateSkills={candidateSkills} requirement={requiredSkills} />
            </Box>
            <Box display={"flex"} gap={2}>
              {candidateSkills.length === 0 && <Typography>Not specified</Typography>}
              {candidateSkills.length > 0 &&
                candidateSkills.map((skill) => (
                  <Typography key={skill} fontWeight={500}>
                    {skill}
                  </Typography>
                ))}
            </Box>
          </section>
        </>
      )}
    </Container>
  );
}

export default ApplicationDetail;
