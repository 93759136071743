import { useQuery } from "@tanstack/react-query";
import { getAllSkills } from "app/api/skill_api";

export const useGetSkills = ({ keys, select = (data) => data }) => {
  return useQuery({
    queryKey: ["get_skills", ...keys],
    queryFn: () => getAllSkills(),
    select: select
  });
};
