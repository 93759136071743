import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import CustomDatePicker from "app/components/form-components/DatePicker";
import { useGetSkills } from "app/hooks/skill/useGetSkills";
import dayjs from "dayjs";
import { useMultiStepForm } from "../Context/FormContext";
import { extractNumbers } from "app/utils/utils";
function BasicDetailStep(props) {
  const { FormState, updateField } = useMultiStepForm();
  const { jp_dob, jp_designation, jp_age, jp_about_me, jp_skills } = FormState;

  const onDateChange = (e) => {
    updateField("jp_dob", e.$d);
  };

  const { data: skills } = useGetSkills({
    keys: ["get_skills"],
    select: (skills) => {
      return skills.map(({ jp_id, jp_skill }) => ({ jp_id, label: jp_skill }));
    }
  });
  const onAgeChange = (e) => {
    updateField("jp_age", extractNumbers(e.target.value));
  };
  const onDesignationChange = (e) => updateField("jp_designation", e.target.value);
  const onBioChange = (e) => updateField("jp_about_me", e.target.value);
  const onSkillsChange = (value) => updateField("jp_skills", value);

  return (
    <Box width={"100%"}>
      <Typography variant="h5" fontWeight={"bold"}>
        Add Basic Detail
      </Typography>
      <Typography variant="subtitle2" color={"gray"} mb={2}>
        *All fields are mandatory
      </Typography>
      <Box display={"flex"} gap={2}>
        <CustomDatePicker
          fullWidth
          label={"D.O.B *"}
          name={"jp_dob"}
          onChange={onDateChange}
          value={dayjs(jp_dob)}
          disableFuture={true}
        />
        <TextField
          value={jp_age}
          label={"Age *"}
          name={"jp_age"}
          fullWidth
          onChange={onAgeChange}
        />
        <TextField
          label={"Designation"}
          name={"jp_designation"}
          defaultValue={jp_designation}
          fullWidth
          onChange={onDesignationChange}
        />
      </Box>
      <Autocomplete
        sx={{ my: 2 }}
        multiple
        defaultValue={jp_skills || []}
        options={skills || []}
        onChange={(e, value) => onSkillsChange(value)}
        renderInput={(params) => (
          <TextField {...params} label="Select Skills *" placeholder="Skills" />
        )}
      />
      <TextField
        multiline
        label={"Bio *"}
        fullWidth
        defaultValue={jp_about_me}
        rows={8}
        name={"jp_about_me"}
        onChange={onBioChange}
      />
    </Box>
  );
}

export default BasicDetailStep;
