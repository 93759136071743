import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function UpdateProfileButton({ job }) {
  return (
    <Box display="flex" flexDirection={"column"} gap={1} justifyContent={"center"}>
      <Link to={"/jobs/apply"} state={{ selectedJob: job }}>
        <Button variant="contained">Update Profile and Apply</Button>
      </Link>
      <Typography variant="body1" sx={{ color: "gray", textAlign: "center" }}>
        Your profile is incomplete
      </Typography>
    </Box>
  );
}

export default UpdateProfileButton;
