import React from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useMultiStepForm } from "../Context/FormContext";
import { QualificationOptions } from "../../Constants";
import CustomDatePicker from "app/components/form-components/DatePicker";
import dayjs from "dayjs";
function EducationDetailStep(props) {
  const { FormState, updateField } = useMultiStepForm();

  return (
    <Box>
      <Typography variant="h5" fontWeight={"bold"}>
        Add Employment Details
      </Typography>
      <Typography variant="subtitle2" color={"gray"} mb={2}>
        *All fields are mandatory
      </Typography>
      <Box display={"flex"} gap={2} alignItems={"start"}>
        <Autocomplete
          fullWidth
          options={QualificationOptions || []}
          defaultValue={FormState.jp_qualification}
          onChange={(e, value) => {
            updateField("jp_qualification", value);
          }}
          renderInput={(params) => <TextField {...params} label="Highest Qualification Level *" />}
        />
        <TextField
          onChange={(e) => updateField("jp_degree_name", e.target.value)}
          fullWidth
          defaultValue={FormState.jp_degree_name}
          name={"jp_degree_name"}
          label="Degree *"
        />
        <CustomDatePicker
          fullWidth
          label={"Passing Year *"}
          name={"jp_passingyear"}
          onChange={(e) => updateField("jp_passingyear", e.$d)}
          value={dayjs(FormState.jp_passingyear)}
          disableFuture
        />
      </Box>
    </Box>
  );
}

export default EducationDetailStep;
