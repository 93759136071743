import { forwardRef } from "react";
import { Typography } from "@mui/material";
import { TextBGColor } from "../JobList";
const GrayBGText = forwardRef((props, ref) => {
  return (
    <Typography
      ref={ref}
      key={props.loc}
      sx={{
        color: "gray",
        bgcolor: TextBGColor,
        borderRadius: "2px",
        padding: "2px 4px"
      }}
    >
      {props.loc}
    </Typography>
  );
});

export default GrayBGText;
