import { useState } from "react";
import { InputAdornment } from "@mui/material";
import EmailVerifyAdornment from "../EmailVerifyAdornment";
import FormTextField from "app/components/form-components/FormTextField";
import useAuth from "app/hooks/useAuth";
import { isCandidateExists } from "app/api/candidate_api";
import { useGenerateOTP } from "app/hooks/otp/useGenerateOTP";
import { EmailRegex } from "app/utils/global_constants";
function Email({
  control,
  email,
  isEmailVerified,
  watch,
  setError,
  clearErrors,
  getValues,
  queryClient,
  openVerifyEmail,
  setEmailVerified
}) {
  const { user } = useAuth();

  const handleGenerateOTPSuccess = () => {
    clearErrors("jp_email");
    openVerifyEmail(true);
  };

  const handleGenerateOTPError = () => {
    setError("jp_email", { type: "manual", message: "Too many attempts made to verify" });
  };

  const { mutate: generateOTP, isPending: isGenerating } = useGenerateOTP(
    handleGenerateOTPSuccess,
    handleGenerateOTPError
  );

  const prev_email_value = watch("jp_email");
  const [isEmailValid, setIsEmailValid] = useState(false);
  let isVerified = 0;

  const handleOpenVerifyOTPDialog = () => {
    const email = getValues("jp_email");
    const name = getValues("jp_candidate_name");

    generateOTP({ email, name, type: "reverify-email" });
  };

  const handleEmailBlur = async (role) => {
    const email = getValues("jp_email");
    if (role !== "SA") return;
    if (prev_email_value === email) return; // If the email has not been edited, we won't check for its presence in the database.
    try {
      const isExists = await queryClient.fetchQuery({
        queryKey: ["is_candidate_exists"],
        queryFn: () => isCandidateExists(email)
      });
      if (isExists) {
        setError("jp_email", { type: "manual", message: "Email already exists" });
      } else {
        clearErrors("jp_email");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateEmail = (e) => {
    const email = e.target.value;
    if (EmailRegex.test(email)) {
      setIsEmailValid(true);
      clearErrors("jp_email");
    } else {
      if (isEmailValid) setIsEmailValid(false);
      setError("jp_email", { type: "manual", message: "Invalid email" });
    }
  };

  if (email) {
    if (prev_email_value === email) {
      isVerified = isEmailVerified;
    } else {
      isVerified = 0;
    }
  } else {
    isVerified = 1;
  }

  return (
    <FormTextField
      name="jp_email"
      control={control}
      label={"Email * "}
      defaultValue={email}
      fullWidth={true}
      type={"email"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {user.role !== "SA" && (
              <EmailVerifyAdornment
                isDisabled={!isEmailValid}
                isVerified={isVerified}
                isGenerating={isGenerating}
                onClick={handleOpenVerifyOTPDialog}
              />
            )}
          </InputAdornment>
        )
      }}
      onInputChange={(e) => validateEmail(e)}
      onBlur={() => handleEmailBlur(user.role)}
      rules={{ pattern: { value: EmailRegex, message: "Invalid email format" } }}
      helperText={"Email is required"}
    />
  );
}

export default Email;
