import { differenceInSeconds } from "date-fns";
import dayjs from "dayjs";

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match("rgba")) {
    let triplet = hex.slice(5).split(",").slice(0, -1).join(",");
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
};

export function generateOTP() {
  const min = 100000; // Minimum 6-digit number (100000)
  const max = 999999; // Maximum 6-digit number (999999)
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumber;
}

export const extractNumbers = (value) => {
  return value.replace(/[^0-9]/g, "");
};

export const RouteTitleMap = {
  "/jobs/listings": "Job Listings",
  "/candidate/listings": "Candidate List",
  "/company/listings": "Company List",
  "/applications/current": "Applied Candidates",
  "/dashboard/default": "Dashboard",
  "/jobs/candidate/applied": "Your Applications",
  "/jobs/company/applied": "Applied Candidates",
  "/jobs/postjob": "Post a Job",
  "/candidate/addCandidate": "Add New Candidate",
  "/company/addCompany": "Add New Company",
  "/trash": "Deleted Items"
};

export const getRouteTitle = (route) => {
  if (route.includes("/jobs/listings")) return "Job Listings";
  if (route.includes("/candidate/listings")) return "Candidate List";
  if (route.includes("/company/listings")) return "Company List";
  if (route.includes("/applications/current")) return "Applied Candidates";
  if (route.includes("/dashboard/default")) return "Dashboard";
  if (route.includes("/jobs/candidate/applied")) return "Your Applications";
  if (route.includes("/jobs/company/applied")) return "Applied Candidates";
  if (route.includes("/jobs/postjob")) return "Post a Job";
  if (route.includes("/candidate/addCandidate")) return "Add New Candidate";
  if (route.includes("/company/addCompany")) return "Add New Company";
  if (route.includes("/trash")) return "Deleted Items";
};

export function capitalizeWords(strArray) {
  // Split the string into an array of words
  const capitalizedArray = strArray.map((str) => {
    // Split the string into an array of words
    const words = str.split(" ");

    // Capitalize the first character of each word
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the capitalized words back into a single string
    return capitalizedWords.join(" ");
  });
  return capitalizedArray.join(",");
}

function currentYPosition(elm) {
  if (!window && !elm) return;

  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  var startY = currentYPosition(scrollableElement);
  var stopY = elmYPosition(elm);

  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function isValidImageSize(image, validSize) {}

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

// Reduces a list of objects to a single string of values separated by a comma
export function convertListToString(values) {
  return values.reduce((acc, current, index, array) => {
    if (index === array.length - 1) {
      return acc + current.label;
    } else {
      return acc + current.label + "| ";
    }
  }, "");
}

export function getCurrentTimeStamp() {
  return new Date().toISOString().slice(0, 19).replace("T", " ");
}

export const previewResume = (resume) => {
  let url;
  if (resume instanceof File) {
    url = URL.createObjectURL(resume);
  } else {
    const fileBlob = new Blob([resume], { type: "application/pdf" });
    const file = new File([fileBlob], "resume.pdf", { type: "application/pdf" });
    url = URL.createObjectURL(file);
  }
  window.open(url, "_blank");
  window.URL.revokeObjectURL(url);
};

export function convertISODateTimeToDate(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = dateTime.toLocaleDateString("en-IN", options);
  return formattedDate;
}

export function convertToBase64(value) {
  try {
    const base64 = btoa(
      new Uint8Array(value).reduce((data, byte) => data + String.fromCharCode(byte), "")
    );
    return base64;
  } catch (error) {
    console.log(error);
    return "";
  }
}

export function getInitialsOfName(name) {
  if (!name) return "";
  const splitName = name.trim().split(" ");
  if (splitName.length > 1) {
    return `${splitName[0][0]}${splitName[1][0]}`;
  } else {
    return name.charAt(0);
  }
}

export function createObjectURLFromFile(file) {
  if (file) {
    return URL.createObjectURL(file);
  }
  return null;
}

export function createObjectURLFromBinary(blobData) {
  if (blobData) {
    const imgBlob = new Blob([blobData], { type: "image/jpg" });
    return URL.createObjectURL(imgBlob);
  }
  return null;
}

export function calculateAge(date) {
  const dob = new Date(date);
  const now = new Date();

  let age = now.getFullYear() - dob.getFullYear();

  if (
    now.getMonth() < dob.getMonth() ||
    (now.getMonth() === dob.getMonth() && now.getDate() < dob.getDate())
  ) {
    age--;
  }

  return age;
}

export function isFileLimitExceeded({ fileSize, limit }) {
  if (fileSize > limit * 1024) {
    return true;
  }
  return false;
}

export function getStatusColor(status) {
  let color = "inherit";
  let bgColor = "inherit";
  if (status === "rejected") {
    color = "red";
    bgColor = "#ef9a9a";
  } else if (status === "pending") {
    color = "gray";
    bgColor = "lightgray";
  } else if (status === "accepted") {
    color = "green";
    bgColor = "lightGreen";
  } else if (status === "shortlisted") {
    color = "#2196f3";
    bgColor = "lightblue";
  }

  return { color, bgColor };
}

export function capitalizeFirstLetter(str) {
  if (!str || typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function validateDate(dateValue, requiredMsg) {
  if (dayjs.isDayjs(dateValue) && isNaN(dateValue.$d.getTime())) {
    return requiredMsg;
  }
  if (!dayjs(dateValue).isValid()) {
    return "Invalid date";
  }

  // Define the date range
  const currentYear = dayjs().year();
  const minYear = currentYear - 100;

  // Extract the year and compare the full date
  const graduationYear = dayjs(dateValue).year();
  const graduationDate = dayjs(dateValue);
  const today = dayjs();

  // Check if the graduation year is too far in the past
  if (graduationYear < minYear) {
    return `Date must be no earlier than ${minYear}.`;
  }

  // Check if the date is in the future
  if (graduationDate.isAfter(today)) {
    return "Date cannot be in the future.";
  }

  return true;
}
