import React from "react";
import { Grid, Typography, Box, Button, Tab, Tabs } from "@mui/material";
import { useMultiStepForm } from "../Context/FormContext";
import { convertListToString } from "app/utils/utils";
import { useState } from "react";
import DOMPurify from "dompurify";
import { usePostApplication } from "app/hooks/application/usePostApplication";
import useUpdateCandidate from "app/hooks/candidate/useUpdateCandidate";
import useAuth from "app/hooks/useAuth";
import useGetCandidate from "app/hooks/candidate/useGetCandidate";
import { getCurrentTimeStamp } from "app/utils/utils";
import LoadingBackdrop from "app/components/LoadingBackdrop";
import { useNavigate } from "react-router-dom";

function TitleValue({ title, value, sx }) {
  return (
    <Box sx={sx}>
      <Typography color={"gray"}>{title}</Typography>
      <Typography variant="subtitle1">{value}</Typography>
    </Box>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const FinalStep = ({ handleReset, job }) => {
  const [tab, setTab] = useState(0);
  const [openLoading, setOpenLoading] = useState(false);
  const { user, updateUser } = useAuth();
  const navigate = useNavigate();
  const { FormState } = useMultiStepForm();

  const { data: candidate } = useGetCandidate(user?.id);
  const { mutateAsync: apply, isPending: isApplying } = usePostApplication({
    onSuccess: () => {
      setOpenLoading(false);
      navigate("/");
    },
    onApply: () => console.log("applied")
  });
  const {
    data: updateResult,
    mutateAsync: updateCandidate,
    isPending: isUpdating
  } = useUpdateCandidate(
    user.id,
    () => {},
    () => {},
    (data) => {
      const updatedUser = data.candidate;
      updateUser({
        role: "candidate",
        name: updatedUser.jp_candidate_name,
        email: updatedUser.jp_email,
        id: updatedUser.jp_id
      });
    }
  );

  const {
    jp_dob,
    jp_age,
    jp_designation,
    jp_address,
    jp_country,
    jp_state,
    jp_city,
    jp_qualification,
    jp_degree_name,
    jp_passingyear,
    jp_current_company,
    jp_current_ctc,
    jp_notice_period,
    jp_yoe,
    jp_skills,
    jp_about_me
  } = FormState;

  const sanitizedDescription = DOMPurify.sanitize(job.jp_description);

  const profileFields = [
    { title: "D.O.B", value: jp_dob?.toLocaleDateString() },
    { title: "Age", value: jp_age },
    { title: "Designation", value: jp_designation },
    { title: "Address", value: jp_address },
    { title: "Country", value: jp_country },
    { title: "State", value: jp_state },
    { title: "City", value: jp_city },
    { title: "Qualification", value: jp_qualification?.label },
    { title: "Specialization", value: jp_degree_name },
    { title: "Graduation Date", value: jp_passingyear?.toLocaleDateString() },
    { title: "Current Company", value: jp_current_company || "" },
    { title: "Current CTC", value: jp_current_ctc || "" },
    { title: "Notice Period", value: jp_notice_period || "" },
    { title: "Years Of Experience", value: jp_yoe || "" },
    { title: "Skills", value: convertListToString(jp_skills) || "" }
  ];

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const getProfileFormData = (FormState) => {
    const fd = new FormData();
    Object.entries(FormState).forEach(([key, value]) => {
      if (key === "jp_qualification") {
        fd.append(key, value.label);
      } else {
        fd.append(key, value);
      }
    });
    fd.set("jp_skills", convertListToString(FormState.jp_skills));
    fd.set("jp_updated_at", getCurrentTimeStamp());
    fd.set("jp_updated_by", user.id);

    return fd;
  };

  const getJobApplication = (job, candidate) => {
    const application = {
      jp_company_id: job.company.jp_id,
      jp_jobpost_id: job.jp_id,
      jp_candidate_id: user.id,
      jp_status: "pending",
      jp_is_share_to_company: 0,
      mail: {
        jp_candidate_name: user.name,
        jp_email: user.email,
        jp_job_title: job.jp_title,
        jp_phone: candidate ? candidate.jp_contactno : "Not specified",
        jp_company_name: job ? job.company.jp_company_name : "",
        jp_company_email: job ? job.company.jp_email : undefined
      }
    };
    return application;
  };

  const updateProfileAndApply = async (job, candidate, formState) => {
    try {
      setOpenLoading(true);
      const profileFD = getProfileFormData(formState);
      const application = getJobApplication(job, candidate);
      await updateCandidate({ data: profileFD });
      await apply({ data: application });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box px={2}>
      <LoadingBackdrop open={openLoading} onClose={() => {}} />
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label={"Review Profile"} />
        <Tab label={"Review Job"} />
      </Tabs>
      <CustomTabPanel value={tab} index={0}>
        <Grid container display={"flex"} gap={4} justifyContent={"space-between"}>
          {profileFields.map((field, index) => (
            <Grid item xs={2} key={`profile-field-${index}`}>
              <TitleValue title={field.title} value={field.value} />
            </Grid>
          ))}
        </Grid>
        <TitleValue title={"Bio"} value={jp_about_me} sx={{ mt: 4 }} />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <Typography variant="h5">{job.jp_title}</Typography>
        <Typography variant="subtitle1">{job.company.jp_company_name}</Typography>
        <Box
          component={"div"}
          sx={{ overflow: "auto", p: 2, maxHeight: "600px" }}
          dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
        />
      </CustomTabPanel>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button onClick={handleReset}>Reset</Button>
        <Button
          variant={"contained"}
          onClick={() => updateProfileAndApply(job, candidate, FormState)}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default FinalStep;
