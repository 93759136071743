import { LocationOn, Verified } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getCandidateApplications } from "app/api/application_api";
import CustomDialog from "app/components/CustomDialog";
import Loading from "app/components/MatxLoading";
import { usePostApplication } from "app/hooks/application/usePostApplication";
import useGetCandidate from "app/hooks/candidate/useGetCandidate";
import useGetJob from "app/hooks/job/useGetJob";
import useAuth from "app/hooks/useAuth";
import { formatExp } from "app/utils/global_constants";
import DOMPurify from "dompurify";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GrayBGText from "../landing_page/shared/Job List Item/GrayBGText";
import JobCities from "../landing_page/shared/Job List Item/JobCities";
import SquareImage from "../landing_page/shared/SquareImage";
import JobBenefitsRequired from "./shared/Job Detail Dialog/JobBenefitsRequired";
import JobSalaryRange from "./shared/Job Detail Dialog/JobSalaryRange";
import JobSkillsRequired from "./shared/Job Detail Dialog/JobSkillsRequired";
import AppliedText from "./shared/Job Detail/AppliedText";
import ApplyDialogContent from "./shared/Job Detail/ApplyDialogContent";
import StartDateEndDate from "./shared/Job Detail/StartDateEndDate";
import UpdateProfileButton from "./shared/Job Detail/UpdateProfileButton";

function getUniqueBenefits(benefits) {
  const uniqueBenefits = new Set(benefits.map((benefit) => benefit.jp_benefit));
  return [...uniqueBenefits];
}

const ActionButtonText = {
  apply: "Apply",
  login: "Login To Apply"
};

function JobDetail() {
  // Job Post Id
  const { id, job } = useParams();
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isEnabled = isAuthenticated && id && user?.role === "candidate";

  const [application, setApplication] = useState();
  const [confirmApply, setConfirmApply] = useState(false); // controls opening and closing of dialog
  const handleCloseDialog = () => setConfirmApply(false);

  const { data: selectedJob, isFetching: isLoadingJobs } = useGetJob(id, false, Infinity);
  const { data: candidate } = useGetCandidate(user?.id, "");
  const { mutate: apply, isPending: isApplying } = usePostApplication({
    onSuccess: () => {
      handleCloseDialog();
      navigate(0);
    },
    onApply: () => console.log("applied")
  });
  const { data: applicationStatus } = useQuery({
    queryKey: ["applicationByCandidate"],
    queryFn: () => getCandidateApplications(user?.id, id),
    staleTime: Infinity,
    enabled: isEnabled
  });

  const [showHiddenBenefits, setShowHiddenBenefits] = useState(false);
  const [showHiddenSkills, setShowHiddenSkills] = useState(false);

  const isCandidate = user?.role === "candidate";
  const isApplied = isCandidate && applicationStatus?.length > 0;
  const isResumeUploaded = candidate?.jp_resume !== null;
  let actionButtonText = "";
  if (isAuthenticated && !isApplied) {
    actionButtonText = ActionButtonText.apply;
  } else {
    actionButtonText = ActionButtonText.login;
  }

  let JobBenefits = [];
  let JobSkills = [];
  let HiddenSkills = [];
  let HiddenBenefits = [];
  let sanitizedDescription = "";
  let locationType = "";
  let keywords = "";
  let jobType = "";
  let cities = [];
  let exp = "";
  let minSalary = "";
  let maxSalary = "";

  if (selectedJob) {
    JobBenefits = getUniqueBenefits(selectedJob.Benefits).slice(0, 3);
    JobSkills = selectedJob.jp_required_skills?.split("| ");
    HiddenSkills = selectedJob.jp_required_skills?.split("| ").slice(3);
    HiddenBenefits = getUniqueBenefits(selectedJob.Benefits).slice(3);
    sanitizedDescription = DOMPurify.sanitize(selectedJob.jp_description);
    cities = selectedJob.jp_city ? selectedJob.jp_city.split("| ") : [];
    exp =
      selectedJob.jp_min_exp && selectedJob.jp_max_exp
        ? `${formatExp(selectedJob.jp_min_exp)} - ${formatExp(selectedJob.jp_max_exp)} yrs`
        : "Fresher";
    locationType = selectedJob.jp_location;
    jobType = selectedJob.jp_type;
    keywords = selectedJob.jp_keywords ? selectedJob.jp_keywords.split("| ").join(", ") : [];
    minSalary = selectedJob.jp_minimum_salary;
    maxSalary = selectedJob.jp_maximum_salary;
  }

  const onClickActionButton = (candidate, job, user) => {
    if (!isAuthenticated) {
      navigate("/session/login/candidate", { state: { from: location.pathname } });
    } else {
      const application = {
        jp_company_id: job.company.jp_id,
        jp_jobpost_id: job.jp_id,
        jp_candidate_id: user.id,
        jp_status: "pending",
        jp_is_share_to_company: 0,
        mail: {
          jp_candidate_name: user.name,
          jp_email: user.email,
          jp_job_title: job.jp_title,
          jp_phone: candidate ? candidate.jp_contactno : "Not specified",
          jp_company_name: job ? job.company.jp_company_name : "",
          jp_company_email: job ? job.company.jp_email : undefined
        }
      };
      setConfirmApply(true);
      setApplication(application);
    }
  };

  return (
    <Container sx={{ backgroundColor: "#eee", height: "100%" }}>
      <Helmet>
        <title>{job}</title>
        <meta name="description" content={sanitizedDescription} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      {user && (
        <CustomDialog
          open={confirmApply}
          dialogTitle={`Apply To ${selectedJob?.jp_title}`}
          onClose={handleCloseDialog}
          maxWidth={"sm"}
          fullWidth
        >
          <ApplyDialogContent
            handleCloseDialog={handleCloseDialog}
            apply={apply}
            application={application}
            isApplying={isApplying}
          />
        </CustomDialog>
      )}
      {isLoadingJobs && <Loading />}
      {!isLoadingJobs && selectedJob && (
        <Box sx={{ m: 0, p: 2 }}>
          {/** Job Title and Company Name*/}
          <Box width={"100%"}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
              <Typography variant="h5">{selectedJob.jp_title}</Typography>
              {!isApplied && (
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                  {isCandidate && isResumeUploaded && (
                    <LoadingButton
                      onClick={() => onClickActionButton(candidate, selectedJob, user)}
                      variant="contained"
                      sx={{ width: "200px" }}
                      disabled={!isResumeUploaded}
                      px={2}
                    >
                      {actionButtonText}
                    </LoadingButton>
                  )}
                  {!isResumeUploaded && <UpdateProfileButton job={selectedJob} />}
                </Box>
              )}
              {!isAuthenticated && (
                <Button onClick={onClickActionButton} variant="contained" px={2}>
                  {ActionButtonText.login}
                </Button>
              )}
              {isApplied && isCandidate && <AppliedText />}
            </Box>
            <Grid container gap={1}>
              <Grid item>
                <SquareImage
                  companyId={selectedJob.company.jp_id}
                  companyName={selectedJob.jp_company_name}
                />
              </Grid>
              <Grid item mb={1}>
                <Box display="flex" gap={1} alignItems={"center"}>
                  <Typography variant="subtitle1" fontSize={"1.2em"} color={"darkgray"}>
                    {selectedJob.company.jp_company_name}
                  </Typography>
                  {selectedJob.company.jp_is_verified ? <Verified sx={{ color: "green" }} /> : ""}
                </Box>
                <Box display={"flex"} gap={2} alignItems={"center"} flexWrap={"wrap"}>
                  {/* Job Type */}
                  <GrayBGText loc={jobType} />
                  {/* Location Type */}
                  <GrayBGText loc={locationType} />
                  {/* Experience */}
                  <GrayBGText loc={exp} />
                  {/* Cities */}
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    sx={{ flexBasis: { xs: "100%", md: "auto" } }}
                  >
                    <LocationOn />
                    <JobCities cities={cities} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {/** Start Date - End Date **/}
            <StartDateEndDate
              startDate={selectedJob.jp_start_date}
              endDate={selectedJob.jp_end_date}
            />
          </Box>
          <Grid container rowGap={3}>
            <Grid item md={9}>
              <Box
                component={"div"}
                sx={{ overflow: "auto", p: 2, maxHeight: "600px" }}
                dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
              />
            </Grid>
            <Grid item md={3} paddingLeft={"20px"}>
              <Box sx={{ width: "100%" }}>
                <JobSalaryRange minSalaryRange={minSalary} maxSalaryRange={maxSalary} />
                <JobSkillsRequired
                  showHiddenSkills={showHiddenSkills}
                  hiddenSkills={HiddenSkills}
                  jobSkills={JobSkills}
                  setShowHiddenSkills={setShowHiddenSkills}
                />
                <JobBenefitsRequired
                  allBenefits={selectedJob?.Benefits}
                  showHiddenBenefits={showHiddenBenefits}
                  hiddenBenefits={HiddenBenefits}
                  jobBenefits={JobBenefits}
                  setShowHiddenBenefits={setShowHiddenBenefits}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
}

export default JobDetail;
