import React from "react";
import { Button, Backdrop, CircularProgress } from "@mui/material";
function LoadingBackdrop({ open, onClose }) {
  return (
    <Backdrop
      sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
      open={open}
      onClick={onClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingBackdrop;
