import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { convertISODateTimeToDate } from "app/utils/utils";
const DateText = styled(Typography)(() => ({
  fontWeight: 500,
  color: "darkslategray"
}));
function StartDateEndDate({ startDate, endDate }) {
  return (
    <Box display="flex" gap={{ xs: 1, md: 2 }} alignItems={"center"}>
      <DateText>Posted On: {convertISODateTimeToDate(startDate)}</DateText>
      <Box sx={{ width: "10px", height: "2px", background: "black" }}></Box>
      <DateText>Apply By: {convertISODateTimeToDate(endDate)}</DateText>
    </Box>
  );
}

export default StartDateEndDate;
