import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
// CUSTOM COMPONENT
import { MatxLoading } from "app/components";
import { setAuthToken } from "app/api/api";
import { registerUser } from "app/api/login_api";
import { useNavigate } from "react-router-dom";

const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false,
  error: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialized: true, user };
    }

    case "LOGIN": {
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }

    case "LOGOUT": {
      localStorage.removeItem("token");
      return { ...state, isAuthenticated: false, user: null };
    }

    case "REGISTER": {
      const { user } = action.payload;
      return { ...state, user };
    }

    case "UPDATE": {
      const { user } = action.payload;
      return { ...state, user: user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {},
  updateUser: () => {}
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password, type) => {
    try {
      const response = await axios.post("/login", { email, password, type });

      localStorage.setItem("token", response.data.token);

      setAuthToken(response.data.token);

      const { user } = response.data;
      if (response.status === 200) {
        dispatch({ type: "LOGIN", payload: { user } });
        return response.data;
      }
    } catch (e) {
      const res = e.response;
      if (res.status === 401 || res.status === 409) {
        return res.data;
      }
    }
  };

  const register = async (data, type) => {
    let responseData = {};
    try {
      if (type === "candidate") {
        const { jp_candidate_name, jp_email, jp_password, jp_cin, jp_contactno } = data;
        const response = await registerUser(
          { jp_candidate_name, jp_email, jp_password, jp_contactno },
          type
        );

        const { jp_id } = response;
        responseData = {
          jp_id,
          jp_candidate_name,
          jp_email,
          role: type
        };
      }

      if (type === "company") {
        const { jp_company_name, jp_email, jp_password, jp_contact_no, jp_cin } = data;
        const response = await registerUser(
          { jp_company_name, jp_email, jp_password, jp_contact_no, jp_cin, jp_is_verified: false },
          type
        );

        const { jp_id } = response;
        responseData = {
          jp_id,
          jp_company_name,
          jp_email,
          role: type
        };
      }

      dispatch({
        type: "REGISTER",
        payload: { responseData, message: "Successfully registered" }
      });
      return { error: false, message: "Successfully registered" };
    } catch (e) {
      return { error: true, message: e.response.data.message };
    }
  };

  const logout = () => {
    if (state.user.role === "SA") {
      navigate("/session/login/admin");
    } else if (state.user.role === "candidate") {
      navigate("/session/login/candidate");
    } else {
      navigate("/session/login/company");
    }
    dispatch({ type: "LOGOUT" });
  };

  const updateUser = (user) => {
    dispatch({ type: "UPDATE", payload: { user } });
  };

  useEffect(() => {
    const getUserProfile = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        setAuthToken(token);
        try {
          const res = await axios.get("/profile", { headers: { Authorization: token } });
          const userData = res.data.user;

          if (userData.isDeleted) {
            localStorage.removeItem("token");
            logout();
            return;
          }

          dispatch({ type: "INIT", payload: { isAuthenticated: true, user: userData } });
        } catch (error) {
          dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
        }
      } else {
        dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
      }
    };

    getUserProfile();

    const profileInterval = setInterval(() => {
      getUserProfile();
    }, 60000);

    return () => clearInterval(profileInterval);
  }, []);

  // SHOW LOADER
  if (!state.isInitialized) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
