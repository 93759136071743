import React from "react";
import { Box } from "@mui/material";
import { H4 } from "app/components/Typography";
function AppliedText(props) {
  return (
    <Box maxWidth={"300px"}>
      <H4
        sx={{
          color: "#5a8da1",
          backgroundColor: "lightblue",
          padding: "4px 6px 4px 6px",
          borderRadius: "5px",
          fontWeight: "bold",
          width: "100%",
          textAlign: "center"
        }}
        mx={"auto"}
      >
        Applied
      </H4>
    </Box>
  );
}

export default AppliedText;
