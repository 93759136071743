import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
function CustomDatePicker({
  value,
  fullWidth,
  error,
  helperText,
  disableFuture,
  label,
  onChange,
  rest
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        fullWidth
        slotProps={{
          textField: {
            fullWidth: fullWidth,
            error: !!error,
            helperText: error ? error.message : null
          }
        }}
        label={label}
        format="DD/MM/YYYY"
        onChange={onChange}
        disableFuture={disableFuture}
        {...rest}
      />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;
